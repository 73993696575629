@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-icons/font/bootstrap-icons";
@import "~jquery-confirm/css/jquery-confirm.css";
@import "~jquery-toast-plugin/src/jquery.toast.css";
@import "../_library/fontawesome-pro-6.5.2-web/css/all.css";

@import "./console.scss";
@import "./security.scss";
@import "./sortable_list.scss";
@import "./theme.scss";
