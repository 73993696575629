/* Change the default button color */
.fc .active {
    background-color: #4F4637;  
    color: white;     
    border: 1px solid #4F4637;             
}

.fc-event-title {
    color: black !important; /* Change this to your preferred color */
    font-weight: bolder;
  }

  
  .fc-event-time {
    color: black; /* Change this to the color you prefer */

  }



/* Specific buttons */
.fc-dayGridYear-button,
.fc-dayGridMonth-button,
.fc-timeGridWeek-button,
.fc-timeGridDay-button {
    background-color: #8D857E; 
    border: 1px solid #4F4637;  
}
.fc-dayGridYear-button:hover,
.fc-dayGridMonth-button:hover,
.fc-timeGridWeek-button:hover,
.fc-timeGridDay-button:hover {
    background-color: #4F4637 ; 
    border: 1px solid #4F4637;  
}
.fc-dayGridYear-button:focus,
.fc-dayGridMonth-button:focus,
.fc-timeGridWeek-button:focus,
.fc-timeGridDay-button:focus {
    background-color: #4F4637 ;  
    border: 1px solid #4F4637;  
}

.fc .fc-dayGridYear-button:active,
.fc .fc-dayGridMonth-button:active,
.fc .fc-timeGridWeek-button:active,
.fc .fc-timeGridDay-button:active {
    background-color: #4F4637;  
    color: white;
    border: 1px solid #4F4637;  
}
 

.fc-prev-button,
.fc-next-button {
    color: black;
    background-color: #F0E2C8;
    border: 1px solid #4F4637;  
}

.fc-prev-button:hover,
.fc-next-button:hover {
    color: black;
    background-color: #E7D9BE;    
}

.fc .fc-prev-button:active,
.fc .fc-next-button:active {
    color: black;
    background-color: #E7D9BE;  
}

#console_navbar {
    position: absolute;
    width: 240px;
    height: 100%;
    overflow-y:auto;
    .nav-link {
        padding: 0.25rem 1rem;
        color: #fff;
        font-weight: lighter;
    }
}

#console_navbar_expand_btn_holder {
    position: absolute;
    width: 50px;
    height: 100%;
    overflow:hidden;
}

#console_panel {
    position: absolute;
    top: 0;
    left: 240px;
    right: 0;
    bottom: 0;
    overflow-y:auto;
    background-color: #fff;
}

#console_breadcrumb {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 56px;
}

#panel-menu {
    float: left;
    height: 100%;
    padding: 70px 50px 10px 20px;
    .form-label {
        margin-bottom: 0px;
    }
    .nav {
        margin-bottom: 10px;
    }
    .nav-link {
        padding: 3px 0px;
        font-size: 80%;
        text-transform: uppercase;
    }
}

#panel-content {
    height: 100%;
    padding: 20px;
    padding-top: 70px;
}

#version_wrapper {
    position: absolute;
    bottom: 15px;
    left: 20px;
}