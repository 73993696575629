.sort-handler {
    cursor: grab;
}

.list-group-handler-wrapper {
    float: left;
    width: 20px;
    padding-top: 3px;
}

.list-group-content-wrapper {
    padding-left: 20px;
}